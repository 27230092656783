<template>
  <div>
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">Items Setup</h3>
      <ul>
        <li>
          <a href="index.html">Items</a>
        </li>
        <li>All Items</li>
      </ul>
    </div>
    <div> -->
    <DataTableComponent :table="table"></DataTableComponent>
    <!-- </div> -->
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
import axios from "axios";
export default {
  data() {
    return {
      table: {
        modal: "Student",
        path: "students",
        headers: [
          {
            text: this.$i18n.t("Code"),
            value: "application_number",
          },
          {
            text: this.$i18n.t("Name"),
            value: "student_full_name",
          },
          {
            text: this.$i18n.t("Gender"),
            value: "student_gender",
          },
          {
            text: this.$i18n.t("Grade"),
            value: "student_grade_level",
          },
          {
            text: this.$i18n.t("Class"),
            value: "class_name",
            sortable: false,
          },
          {
            text: this.$i18n.t("Year"),
            value: "year_name",
          },
          {
            text: this.$i18n.t("Status"),
            value: "status_student",
          },
          {
            text: this.$i18n.t("Fee Plan"),
            value: "feeplan",
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Students"),
        apiEndPoints: {
          list: "students",
          create: "students/store",
          edit: "students/update",
          delete: "students/deleteStudent",
        },
        editedItem: {},
        permissions: {
          list: "list-students",
          add: "add-students",
          edit: "edit-students",
          delete: "delete-students",
          print: "print-student-profile",
          // assign_to_class: "assign-to-class-students",
          show: "show-students",
          export: "export-students",
          import: "import-students",
        },
        filters: {
          academicPeriods: [],
          notAssign: "",
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/students/getAllAcademicPeriods", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          //the token is a variable which holds the token
        },
      })
      .then((res) => {
        if (res.data.status.error == false) {
          this.table.filters.academicPeriods = res.data.data;
        }
      });
  },
};
</script>

<style></style>
